import {post, get, ResponseType, URL} from "@/utils/axios";
import {REPORT_OPTIONS} from "@/utils/api/url";


export const getList = (params: {
    id: number | string,
    page: number,
    size: number,
}) => {
    return get(URL.REPORT_LIST, {params})
}
export const getOptions = () => {
    return get(URL.REPORT_OPTIONS, {})
}
export const pass = (params: { id: number | string }) => {
    return post(URL.REPORT_PASS, {data: params})
}
export const forbid = (params: { id: number | string }) => {
    return post(URL.REPORT_FORBID, {data: params})
}
